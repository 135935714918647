import React, { Suspense, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import axios from "axios"
import { AstPath } from 'prettier'
import request from "../../../components/request/request"
import { useNavigate } from "react-router-dom"
import { postAxios } from 'src/components/axios/Axios'

import { _loginPage as pageDetails } from 'src/config/_config'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import Loading from "../../../components/loader/Loading"
const ChangePassword = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [passwordShow, setPasswordShow] = useState(false)
    const [newPasswordShow, setNewPasswordShow] = useState(false)
    const [repeatPasswordShow, setRepeatPasswordShow] = useState(false)
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const { state } = useLocation();
    const loadershow = useSelector((state) => state.loader)
    useEffect(() => {
        document.title = "SRIFIN EOS Dashboard"
    }, [])

    const handleLogin = async (e) => {
        e.preventDefault()
        setLoading(true)
        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 3000));
        const id = toast.loading("Please wait...")

        // the reuired payload
        const data = {
            "new_password": newPassword,
            "password": password,
            "repeat_new_password": repeatPassword
        }

        // Post request for user login 
        const res = await postAxios(request.changePassword, data)
        if (res?.data?.apiresponse?.type == "OK") {  /* as per the response data structure */
            toast.update(id, { render: res?.data?.apiresponse?.message, type: "success", isLoading: false, autoClose: '2000', toastId: 'loginToast' });
            setTimeout(() => {
                navigate("/login")
            }, 4000)
        } else {
            toast.update(id, { render: res?.data?.apiresponse?.message, type: "error", isLoading: false, autoClose: '1500', toastId: 'loginToast' });
        }
        setLoading(false)
    }
    console.log(pageDetails)
    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={10} lg={8}>
                        <CCol>
                            <CCard className="p-4">
                                <h1 className='text-center'>{pageDetails.loginHeading}</h1>
                            </CCard>
                        </CCol>
                        <CCardGroup className='d-flex flex-column flex-md-row'>
                            <CCard className="p-4 order-2 order-md-1">
                                <CCardBody>
                                    <CForm onSubmit={handleLogin}>
                                        <h3 className=''>Change Password</h3>
                                        <p className="text-medium-emphasis f-16 ">Please choose a secure new password </p>
                                        <div className='position-relative'>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupText>
                                                    <CIcon icon={cilLockLocked} />
                                                </CInputGroupText>
                                                <CFormInput
                                                    type={passwordShow ? "text" : "password"}
                                                    required
                                                    placeholder="Current Password"
                                                    autoComplete="current-password"
                                                    value={password}
                                                    className='f-16'
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                {passwordShow ?
                                                    <i className='pi pi-eye password__icon cursor-pointer' onClick={() => setPasswordShow(false)} /> :
                                                    <i className='pi pi-eye-slash password__icon cursor-pointer' onClick={() => setPasswordShow(true)} />
                                                }
                                            </CInputGroup>
                                        </div>
                                        <div className='position-relative'>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupText>
                                                    <CIcon icon={cilLockLocked} />
                                                </CInputGroupText>
                                                <CFormInput
                                                    type={newPasswordShow ? "text" : "password"}
                                                    required
                                                    placeholder="New Password"
                                                    autoComplete="current-password"
                                                    value={newPassword}
                                                    className='f-16'
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                />
                                                {newPasswordShow ?
                                                    <i className='pi pi-eye password__icon cursor-pointer' onClick={() => setNewPasswordShow(false)} /> :
                                                    <i className='pi pi-eye-slash password__icon cursor-pointer' onClick={() => setNewPasswordShow(true)} />
                                                }
                                            </CInputGroup>
                                        </div>
                                        <div className='position-relative'>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupText>
                                                    <CIcon icon={cilLockLocked} />
                                                </CInputGroupText>
                                                <CFormInput
                                                    type={repeatPasswordShow ? "text" : "password"}
                                                    required
                                                    placeholder="Repeat New Password"
                                                    autoComplete="current-password"
                                                    value={repeatPassword}
                                                    className='f-16'
                                                    onChange={(e) => setRepeatPassword(e.target.value)}
                                                />
                                                {repeatPasswordShow ?
                                                    <i className='pi pi-eye password__icon cursor-pointer' onClick={() => setRepeatPasswordShow(false)} /> :
                                                    <i className='pi pi-eye-slash password__icon cursor-pointer' onClick={() => setRepeatPasswordShow(true)} />
                                                }
                                            </CInputGroup>
                                        </div>
                                        <CRow>
                                            <CCol xs={12}>
                                                <CButton type='submit' color="primary" className="px-4 w-75">
                                                    {loading ? <Loading /> : "Change Password"}
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                            <CCard className="position-relative p-5 login-logo col-md-50 order-1 order-md-2">
                                <img src={pageDetails.logo} className='w-100 h-100' style={{
                                    objectFit: "contain"
                                }} />

                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default ChangePassword
